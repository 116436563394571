import styled from 'styled-components';
import React, { memo } from 'react';

export const SelectContainer = styled.div`
  width: 100%;
  .sub-label {
    font-size: 12px;
    color: #999;
  }
  label {
    color: #898989;
    font-size: 10px;
    text-transform: uppercase;
    display: block;
  }
  ${({ compact }) =>
    compact &&
    `
    .tv-select-container {
      height: 32px;
      .tv-select-arrow {
        padding: 6px;
        display: flex;
      }
    }
  `}
  .form-control {
    background: #f5f5f5;
  }
`;

const Select = ({
  label = null,
  subLabel = null,
  initialValue = null,
  options,
  onChange,
  disabled = false,
  compact = false,
}) => {
  return (
    <SelectContainer className="modal-row" compact={compact}>
      {label && <label>{label}</label>}
      <div className="tv-select-container">
        <select value={initialValue} onChange={(e) => !disabled && onChange(e)} disabled={disabled}>
          {options.map((o) => (
            <option key={`${label}-${o.value ?? o.name ?? o.label ?? o}`}>
              {o.value ?? o.name ?? o.label ?? o}
            </option>
          ))}
        </select>
        <div className="tv-select-arrow">
          <i className="fa fa-angle-down" />
        </div>
      </div>
      {subLabel && <span className="sub-label">{subLabel}</span>}
    </SelectContainer>
  );
};

export default memo(Select);
