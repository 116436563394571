export const prospectScoresV2 = [
  {
    category: 'Team',
    description:
      "Consider the founder/team's prior exits, industry experience, fundraising ability, and reputation.",
    scores: [
      {
        rating: 3,
        seedScore: 0.4,
        lateScore: 0.35,
      },
      {
        rating: 2,
        seedScore: 0.2,
        lateScore: 0.175,
      },
      {
        rating: 1,
        seedScore: 0,
        lateScore: 0,
      },
    ],
  },
  {
    category: 'Market Size Potential',
    description: 'Consider the total market size, sales addressable market and growth rate.',
    scores: [
      {
        rating: 3,
        seedScore: 0.25,
        lateScore: 0.2,
      },
      {
        rating: 2,
        seedScore: 0.13,
        lateScore: 0.1,
      },
      {
        rating: 1,
        seedScore: 0,
        lateScore: 0,
      },
    ],
  },
  {
    category: 'Product or Tech Differentiation',
    description:
      'Consider the long-term defensibility of the product that provides a clear, accumulating advantage.',
    scores: [
      {
        rating: 3,
        seedScore: 0.15,
        lateScore: 0.15,
      },
      {
        rating: 2,
        seedScore: 0.08,
        lateScore: 0.08,
      },
      {
        rating: 1,
        seedScore: 0,
        lateScore: 0,
      },
    ],
  },
  {
    category: 'Competition',
    description:
      "Is the competitive environment conducive for startup(s) to enter and succeed? Consider barriers to entry, customer satisfaction, incumbents' ability to innovate, and cost of capital.",
    scores: [
      {
        rating: 3,
        seedScore: 0.1,
        lateScore: 0.1,
      },
      {
        rating: 2,
        seedScore: 0.05,
        lateScore: 0.05,
      },
      {
        rating: 1,
        seedScore: 0,
        lateScore: 0,
      },
    ],
  },
  {
    category: 'Investor Syndicate',
    description:
      'Assess the quality of investors (ideally board members), focusing on their expertise and influence on the company’s success.',
    scores: [
      {
        rating: 3,
        seedScore: 0.1,
        lateScore: 0.1,
      },
      {
        rating: 2,
        seedScore: 0.05,
        lateScore: 0.05,
      },
      {
        rating: 1,
        seedScore: 0,
        lateScore: 0,
      },
    ],
  },
  {
    category: 'Financial Metrics',
    description:
      'Compare the company’s revenue and growth rate against industry benchmarks. Also consider gross margin, CAC, and burn.',
    scores: [
      {
        rating: 3,
        seedScore: 0,
        lateScore: 0.1,
      },
      {
        rating: 2,
        seedScore: 0,
        lateScore: 0.05,
      },
      {
        rating: 1,
        seedScore: 0,
        lateScore: 0,
      },
    ],
  },
];
