import React, { useCallback, useContext, useMemo, useState } from 'react';
import '../../styles/react-datetime.css';
import styled from 'styled-components';
import { ProspectContext } from '../../contexts/ProspectContext';
import { Card, Flex } from '../primitives';
import ProspectSummaryModal from '../prospect/ProspectSummaryModal';
import ProspectDetails from '../prospect/ProspectDetails';
import { getUserEmail } from '../../utils/Auth';

const SummaryLink = styled.a`
  margin-left: 20px;
`;

export default function CompanyProspect({
  prospectScoreV1,
  prospectScoreV2,
  onWatchlist,
  isVerticalPriority,
}) {
  const email = getUserEmail();
  const [prospectContextState, dispatch] = useContext(ProspectContext);
  const { permalink, prospectId } = prospectContextState;
  const isActive = useMemo(() => !!prospectId, [prospectId]);
  const [modalActive, setModalActive] = useState(false);

  const openProspectSummary = useCallback(() => setModalActive(true), []);

  if (isActive) {
    return (
      <>
        <Card className="card prospects-block" marginBottom="20" fill>
          <div className="card-block">
            <Flex direction="row">
              <h3>Prospect Details</h3>
              {(email === 'colin@thomvest.com' || email === 'nima@thomvest.com') &&
                permalink === 'tilled' && (
                  <SummaryLink href="#" onClick={openProspectSummary}>
                    View summary
                  </SummaryLink>
                )}
            </Flex>

            <ProspectDetails
              prospectScoreV1={prospectScoreV1}
              prospectScoreV2={prospectScoreV2}
              onWatchlist={onWatchlist}
              isVerticalPriority={isVerticalPriority}
            />

            <button
              type="button"
              className="tv-button"
              onClick={() => {
                dispatch({ type: 'SET_MODAL', payload: { modal: 1, isEditing: true } });
              }}
            >
              Edit
            </button>
          </div>
        </Card>

        {modalActive && (
          <ProspectSummaryModal
            close={() => setModalActive(false)}
            permalink={permalink}
            prospectScoreV1={prospectScoreV1}
            prospectScoreV2={prospectScoreV2}
            onWatchlist={onWatchlist}
            isVerticalPriority={isVerticalPriority}
          />
        )}
      </>
    );
  }

  return null;
}
