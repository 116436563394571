/* eslint-disable no-case-declarations */
import React, { createContext, useReducer, useEffect } from 'react';

export const ProspectScoreV2Context = createContext();

export const hasActiveProspectScoreV2 = (prospectScores = []) => {
  return prospectScores.every((score) => score.score > 0 || score.rating > 0);
};

export const createInitialState = () => [
  {
    category: 'Team',
    rating: 0,
    score: 0,
  },
  {
    category: 'Market Size Potential',
    rating: 0,
    score: 0,
  },
  {
    category: 'Product or Tech Differentiation',
    rating: 0,
    score: 0,
  },
  {
    category: 'Competition',
    rating: 0,
    score: 0,
  },
  {
    category: 'Investor Syndicate',
    rating: 0,
    score: 0,
  },
  {
    category: 'Financial Metrics',
    rating: 0,
    score: 0,
  },
];

const reducer = (state, action) => {
  switch (action.type) {
    case 'UPDATE_SCORE':
      const { score, rating, category } = action.payload;
      const updatedState = state.map((a) => {
        if (a.category === category) {
          a.score = score;
          a.rating = rating;
        }
        return a;
      });
      return updatedState;
    case 'UPDATE_PROSPECT_SCORES':
      const prospectScores = action.payload;
      return prospectScores;
    case 'RESET_PROSPECT_SCORES_V2_CONTEXT':
      return createInitialState();
    default:
      return state;
  }
};

export const ProspectScoreV2ContextProvider = ({ prospectScores, children }) => {
  useEffect(() => {
    if (prospectScores && prospectScores.length > 0) {
      dispatch({
        type: 'UPDATE_PROSPECT_SCORES',
        payload: prospectScores,
      });
    }
  }, [prospectScores]);

  const initialState = createInitialState();
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <ProspectScoreV2Context.Provider value={[state, dispatch]}>
      {children}
    </ProspectScoreV2Context.Provider>
  );
};
