import React, { useContext } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { ProspectContext } from '../../contexts/ProspectContext';
import ProspectScore from './ProspectScore';
import { STAGE_TO_TYPE_MAP } from '../../constants';

const getActiveValue = (list) => {
  return list.find((l) => l.active)?.value;
};

export default function ProspectScoresModal() {
  const [state, dispatch] = useContext(ProspectContext);
  const stageValue = getActiveValue(state.stage.options);
  const stageType = STAGE_TO_TYPE_MAP[stageValue];
  const prospectScoresV1 = state.prospectScores.filter((s) => s.stage_type_id === stageType);
  const active = !!state.prospectId;

  return (
    <div>
      <Modal
        backdrop="static"
        show={state.modal === 2}
        onHide={() => dispatch({ type: 'SET_MODAL', payload: { modal: null, isEditing: false } })}
        className="tv-modal tv-company-modal"
      >
        <Modal.Header>
          <Modal.Title>Scoring: {stageType === 1 ? 'Early' : 'Late'} Stage</Modal.Title>
          <Button
            className="modal-close"
            onClick={() =>
              dispatch({ type: 'SET_MODAL', payload: { modal: null, isEditing: false } })
            }
          >
            <i className="fa fa-times" />
          </Button>
        </Modal.Header>
        <Modal.Body>
          <ul className="list-unstyled list-spaced">
            {prospectScoresV1.map((s) => (
              <ProspectScore score={s} key={s.score_id} stageType={stageType} />
            ))}
          </ul>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() =>
              dispatch({ type: 'SET_MODAL', payload: { modal: 1, isEditing: state.isEditing } })
            }
          >
            Go Back
          </Button>
          <Button
            onClick={() =>
              dispatch({ type: 'SET_MODAL', payload: { modal: 3, isEditing: state.isEditing } })
            }
          >
            Next: {active ? 'Update' : 'Add'} Scores (New)
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
