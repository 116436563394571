import React, { useCallback, useEffect, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import styled from 'styled-components';
import { getAccessToken } from '../../utils/Auth';
import { getDocuments, BACKEND_API } from '../../BackendAPI';
import captureError from '../../utils/sentry';
import { Loader, Flex } from '../primitives';
import ProspectDetails from './ProspectDetails';

const ModalContainer = styled(Modal)`
  .modal-dialog {
    max-width: 96%;
    min-height: 91vh;
  }
  .modal-content {
    height: 91vh;
  }
  .modal-header {
    padding: 6px 20px 18px 0;
    margin-bottom: 6px;
  }
  .modal-close {
    position: absolute;
    z-index: 100;
  }
  .outer-grid {
    display: grid;
    grid-template-columns: 50% 50%;
    gap: 20px;
    height: 91%;
  }

  .top-left {
    background-color: #fff;
    height: 77vh;
  }

  .top-right {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0;
  }

  .right-col-1,
  .right-col-2 {
    background-color: #fff;

    .column-content {
      border-left: 1px solid #dcdcdc33;
      padding: 20px;
      height: 100%;
      display: block;
      height: 77vh;
      overflow-y: scroll;
    }
  }

  .right-col-1 {
    .column-content {
      padding: 0;
      padding-right: 20px;

      ul {
        padding-top: 0;
        margin: 0;
      }
    }
  }

  @media (max-width: 991px) {
    .outer-grid {
      grid-template-columns: 1fr;
    }
    .top-left {
      display: none;
    }
  }
`;

export default function ProspectSummaryModal({
  close,
  permalink,
  prospectScoreV1,
  prospectScoreV2,
  onWatchlist,
  isVerticalPriority,
}) {
  const [documentUrl, setDocumentUrl] = useState(null);
  const [deckSummary, setDeckSummary] = useState(null);

  const fetchDocuments = useCallback(async () => {
    try {
      const accessToken = getAccessToken().split(' ')[1];
      const docs = await getDocuments(permalink);
      const document = docs[0];
      const viewUrl = `${BACKEND_API}/company/view_document/${document.uuid}/${accessToken}`;
      setDocumentUrl(viewUrl);
    } catch (err) {
      setDocumentUrl(false);
      captureError(`Failed to fetch ${permalink} documents :${err}`);
    }
  }, [permalink]);

  useEffect(() => {
    fetchDocuments();

    setTimeout(() => {
      setDeckSummary(true);
    }, 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ModalContainer
      backdrop="static"
      show
      onHide={close}
      className="tv-modal tv-prospect-summary-modal"
    >
      <Button className="modal-close" onClick={close}>
        <i className="fa fa-times" />
      </Button>

      <Modal.Body>
        <div className="outer-grid">
          <div className="top-left">
            <Modal.Header>
              <Modal.Title>View Document</Modal.Title>
            </Modal.Header>

            {documentUrl ? (
              <iframe src={documentUrl} width="100%" height="100%" />
            ) : (
              <Flex width="100%" height="77vh" justify="center" align="center">
                {documentUrl === null ? (
                  <Loader />
                ) : (
                  <p>There was an issue loading this document.</p>
                )}
              </Flex>
            )}
          </div>
          <div className="top-right">
            <div className="right-col-1">
              <Modal.Header>
                <Modal.Title>Company Summary</Modal.Title>
              </Modal.Header>
              <div className="column-content">
                {deckSummary ? (
                  <Flex width="100%" height="100%" direction="column">
                    <p>
                      <b>Review of Tilled Pitch Deck:</b>
                    </p>
                    <ul>
                      <li>
                        <b>Business Summary:</b> Tilled provides "PayFac-as-a-Service," solving the
                        problem of high costs and complexities of becoming a fully registered PayFac
                        by offering frictionless onboarding, modern APIs, and managed back-office
                        operations to B2B software companies.
                      </li>
                      <li>
                        <b>Ideal Customer Profile (ICP):</b> B2B software companies with annual
                        processing volumes between $50M-$2B in industries like healthcare,
                        non-profit, property management, and fitness.
                      </li>
                      <li>
                        <b>Active Customers:</b>
                        <ul>
                          <li>LaunchPad (highlighted in the testimonials)</li>
                          <li>N/A for other specific names.</li>
                        </ul>
                      </li>
                      <li>
                        <b>Team:</b>
                        <ul>
                          <li>CEO Caleb Avery has prior entrepreneurial experience.</li>
                          <li>
                            Leadership includes roles in engineering, customer experience, and
                            finance, with significant domain expertise.
                          </li>
                          <li>N/A for notable affiliations.</li>
                        </ul>
                      </li>
                      <li>
                        <b>Why Now?:</b>
                        <ul>
                          <li>
                            Shift towards embedded payments in B2B software aligns with market
                            trends.
                          </li>
                          <li>
                            Increasing demand for seamless and modern payment solutions in
                            vertical-specific SaaS platforms.
                          </li>
                          <li>
                            Growth of the PayFac model driven by dissatisfaction with legacy
                            processors.
                          </li>
                        </ul>
                      </li>
                      <li>
                        <b>Differentiation from Incumbents:</b>
                        <ul>
                          <li>
                            <b>Incumbents:</b> Stripe, Braintree, legacy processors.
                          </li>
                          <li>
                            <b>Differentiators:</b> Faster onboarding, daily commission payouts, and
                            fully managed operations with modern APIs.
                          </li>
                        </ul>
                      </li>
                      <li>
                        <b>Differentiation from Startups:</b>
                        <ul>
                          <li>
                            <b>Startups:</b> N/A for specific names.
                          </li>
                          <li>
                            <b>Differentiators:</b> White-label functionality, strong partner
                            ecosystem, and focus on mid-tier processing volumes.
                          </li>
                        </ul>
                      </li>
                      <li>
                        <b>Current ARR:</b> $9.7M.
                      </li>
                      <li>
                        <b>Current Gross Margin:</b> N/A.
                      </li>
                      <li>
                        <b>Forward ARR Growth Rate:</b> N/A.
                      </li>
                      <li>
                        <b>Financing Details:</b>
                        <ul>
                          <li>N/A on specific funding stages or amounts.</li>
                          <li>
                            Focus on using funds for technical infrastructure, marketing, and
                            scaling customer acquisition.
                          </li>
                        </ul>
                      </li>
                      <li>
                        <b>Devil's Advocate:</b>
                        <ul>
                          <li>
                            Mid-market focus might be a niche, limiting the total addressable
                            market.
                          </li>
                          <li>
                            High dependency on revenue-sharing partnerships could expose margins to
                            negotiation risks.
                          </li>
                          <li>
                            Competitive landscape includes strong players like Stripe, potentially
                            challenging differentiation at scale.
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </Flex>
                ) : (
                  <Flex
                    width="100%"
                    height="100%"
                    justify="center"
                    align="center"
                    direction="column"
                  >
                    <h6>...generating summary</h6>
                    <Loader />
                  </Flex>
                )}
              </div>
            </div>
            <div className="right-col-2">
              <Modal.Header>
                <Modal.Title>Prospect Details</Modal.Title>
              </Modal.Header>
              <div className="column-content">
                <ProspectDetails
                  prospectScoreV1={prospectScoreV1}
                  prospectScoreV2={prospectScoreV2}
                  onWatchlist={onWatchlist}
                  isVerticalPriority={isVerticalPriority}
                  direction="column"
                  isModal
                />
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </ModalContainer>
  );
}
